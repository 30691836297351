import { render, staticRenderFns } from "./AssetTable.html?vue&type=template&id=6fc1a142&scoped=true&"
import script from "./AssetTable.js?vue&type=script&lang=js&"
export * from "./AssetTable.js?vue&type=script&lang=js&"
import style0 from "./AssetTable.scss?vue&type=style&index=0&id=6fc1a142&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fc1a142",
  null
  
)

export default component.exports